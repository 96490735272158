.Search{
    position: relative;
    width: 100%;
    z-index: 0;
}
.Search input{
    padding: .8em .7em .6em 3em;
    width: 100%;
    border-radius: 6px;
    color: var(--dark_color);
    background: none;
    font-size: var(--fsize9);
    border-radius: var(--fsize9);
    border: 0.5px solid var(--border);
    line-height: .5;
}
.Search input::placeholder{
    color: var(--gray);
}
.Search svg{
    position: absolute;
    top: 30%;
    left: var(--fsize8);
    width: var(--fsize8);
    height: var(--fsize8);
    cursor: pointer;
}
.Search svg path{
    stroke: var(--dark_color);
}