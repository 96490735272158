.Header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: var(--header_height);
    font-size: var(--fsize9);
    border-bottom: 0.5px solid var(--border);
}
.content{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: var(--row0);
    margin: 0 auto;
}
.Header .theme{
    cursor: pointer;
}
.logo svg{
    font-size: var(--fsize4);
    height: 90%;
    display: flex;
    justify-content: center;
    margin-left: var(--headerLogo_margin);
}
.logo svg{
    height: var(--fsize6);
}
.logo svg path:nth-child(3){
    fill: var(--dark_color);
}
.header_buttons{
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: var(--header_buttons_width);
    transition: .1s;
}
.bMenu {
    position: relative;
    display: var(--bMenu_display);
    width: var(--header_buttons_width);
    height: 20px;
    background: none;
    border: none;
    appearance: none;
    cursor: pointer;
    z-index: 100;
}
.bMenu span {
    position: absolute;
    left: 0;
    width: var(--fsize4);
    height: 2px;
    margin-left: 1em;
    background-color: var(--dark_color);
    border-radius: 2px;
    transition: .5s;
}
.bMenu span:nth-of-type(1) {
    top: 0;
}
.bMenu span:nth-of-type(2) {
    top: 8px;
    opacity: 1;
    transition: .1s;
}
.bMenu span:nth-of-type(3) {
    bottom: 0;
}
.active span:nth-of-type(1){
    width: var(--fsize7);
    top: 20%;
    transform: rotate(-45deg);
}
.active span:nth-of-type(2) {
    opacity: 0;
}
.active span:nth-of-type(3){
    width: var(--fsize7);
    bottom: 20%;
    transform: rotate(45deg);
}
.show{
    left: 0!important;
}
.hide{
    opacity: 0;
}
.showBack{
    display: block;
}