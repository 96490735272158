.Button{
    font-family: 'GTEestiProDisplay-Medium';
    color: var(--dark_color);
    font-size: var(--fsize9);
    border-radius: var(--fsize9);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.Button span{
    margin-top: .2em;
}
.orange{
    background-color: var(--orange);
    color: var(--stable_light);
}
.light{
    border: 0.5px solid var(--border);
    background-color: #00000000;
}
.small{
    height: 2.2em;
    border-radius: .8em;
    font-size: var(--fsize9);
}
.small span{
    margin: 0 1.5em;
    font-size: var(--fsize9);
}
.large{
    height: 3em;
    padding: 0 1.2em;
}