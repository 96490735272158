.CustomFields{
    border: 1px solid var(--border);
    margin-bottom: 2em;
    border-radius: 1em;
    padding: 1em 2em 2em;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.CustomFields  input{
    width: 100%;
    border: 1.5px solid var(--border);
    border-radius: 1em;
    overflow: hidden;
    font-size: var(--fsize9);
    padding: .7em 1em .5em 1em;
    background: #00000000;
    color: var(--dark_color);
}
input::placeholder {
    color:  var(--dark_color);
}
.h3{
    font-family: 'GTEestiProDisplay-Bold';
    font-size: var(--fsize9);
    margin: 1em 0 .5em;
}
small{
    font-family: 'GTEestiProDisplay-Regular';
    color: var(--gray);
}
.select{
    border: 1.5px solid var(--border);
    border-radius: 1em;
    overflow: hidden;
    width: 100%;
    margin-right: 1em;
    padding: 0 1em;
}
.select select{
    cursor: pointer;
    background: #00000000;
    width: 100%;
    color: var(--dark_color);
    padding: .7em 0 .5em;
    margin-right: .5em;
    font-size: var(--fsize9);
}
.button{
    padding: .7em 0 .5em;
    width: 100%;
    font-size: var(--fsize9);
    background-color: var(--orange);
    color: var(--stable_light);
    border: 1px solid var(--border);
    border-radius: 1em;
    overflow: hidden;
    margin: 1em 0 0;
}
