.ReviewsList{
    overflow-x: scroll;
    font-size: var(--fsize10);
    color: var(--dark_color);
}
.title{
    font-family: 'GTEestiProDisplay-Medium';
    font-size: var(--fsize9);
    min-width: var(--minWidth);
    display: flex;
    justify-content: space-between;
    border-bottom: 0.5px solid var(--border);
}
.title *{
    margin: var(--fsize10);
    width: 20%;
}
.items{
    min-width: var(--minWidth);
}
.item{
    color: var(--dark_color);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.notFinal{
    border-bottom: 0.5px solid var(--border);
}
.item img{
    height: 2.5em;
    margin-right: 1em;
}
.value{
    margin: var(--fsize10);
    width: 20%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}
.reply__title{
    font-size: var(--fsize9);
}
.reply__text{
    border-left: 2px solid var(--orange);
    padding-left: .3em;
}
.action{
    width: 20%;
    display: flex;
    flex-wrap: wrap;
    margin: var(--fsize10);
}
.action a{
    margin: 0 .7em .5em 0;
}
.rating{
    margin: var(--fsize10);
    width: 20%;
    display: flex;
    align-items: center;
}
.rating span{
    margin: .2em .5em 0;
}
.rating svg path{
    fill: var(--orange);
}