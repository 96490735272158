.PayMethods{
    border: 1px solid var(--border);
    margin-bottom: 2em;
    border-radius: 1em;
    padding: 1em 1em 1em 2em;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.h3{
    font-family: 'GTEestiProDisplay-Bold';
    font-size: var(--fsize9);
    margin: 1em 0 .2em;
}
.PayMethod{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;
}
.PayMethod__item{
    border: 1px solid var(--border);
    border-radius: 1em;
    padding:  .5em 1em 1em;
    width: var(--payMethodItem_width);
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 .1em .5em;
}
.PayMethod__item .span{
    font-size: var(--fsize9);
    margin-left: .4em;
    color: var(--dark_color);
}
.PayMethod__item svg{
    margin-top: .5em;
    width: 2em;
    height: 2em;
}