.Team{
    width: 100%;
    padding-right: 1em;
    height: calc(82vh - var(--header_height));
    overflow-x: var(--overflowX);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: flex-start;
}
.block{
    border: 0.5px solid var(--border);
    border-radius: 1em;
    padding: 1.5em 2em;
    margin-bottom: 1em;
    width: 100%;
    display: flex;
    flex-direction: column; 
}
.block h3{
    font-family: 'GTEestiProDisplay-Bold';
    font-size: var(--fsize8);
    margin-bottom: .2em;
}
.blockTitle{
    display: flex;
    justify-content: space-between;
    margin-bottom: .5em;
}
.blockTitle_textTop{
    display: flex;
    align-items: center;
    margin-bottom: .2em;
}
.blockTitle h3{
    margin-right: .5em;
    margin-bottom: 0;
}
.name .bottom{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.purchaseList{
    font-size: var(--fsize10);
    color: var(--dark_color);
    padding: 1em 0;
    overflow-x: var(--overflowX);
    height: 100%;
}
.purchaseList h4{
    font-size: var(--fsize9);
    font-family: 'GTEestiProDisplay-Medium';
}
.title{
    font-family: 'GTEestiProDisplay-Medium';
    font-size: var(--fsize9);
    display: flex;
    min-width: var(--minWidth);
    justify-content: space-between;
    border-bottom: 0.5px solid var(--border);
}
.title *{
    margin: var(--fsize10);
    width: 15%;
}
.avatar{
    min-width: var(--fsize3);
    height: var(--fsize3);
    border-radius: 100%;
    margin-right: 1em;
    border: 0.5px solid var(--border);
}
.info{
    width: 30%;
}
.items{
    min-width: var(--minWidth);
}
.item{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.item_info{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: var(--blackList_item_width); 
    margin: 1em;
    height: 3em;
    position: relative;
    width: 30%; 
}
.item_value{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: var(--blackList_item_width); 
    margin: 1em;
    height: 3em;
    position: relative;
    width: 15%; 
}
.item_value__action{
    display: flex;
    justify-content: flex-end;
    width: 10%;
    align-items: center;
    margin: 1em;
    height: 3em;
    position: relative;
}
.notFinal{
    border-bottom: 0.5px solid var(--border);
}
.action{
    width: 10%;
    display: flex;
    justify-content: flex-end;
}
.action_icon{
    cursor: pointer;
}
.action_icon svg path{
    fill: var(--dark_color);
}
.action_list{
    display: none;
}
.show div{
    display: flex;
}
.ModalMessage{
    width: 700px;
    max-width: 100%;
    padding: 1.5em;
    background: var(--white);
    border-radius: var(--fsize10);
    display: flex;
    justify-content: space-between;
    border: 0.5px solid var(--border);
}
.ModalMessage_content{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}
.ModalMessage svg{
    margin-top: .2em;
    width: var(--fsize7);
    height: var(--fsize7);
    cursor: pointer;
}
.ModalMessage svg path{
    fill: var(--dark_color);
}
.ModalMessage h3{
    font-family: 'GTEestiProDisplay-Bold';
    font-size: var(--fsize8);
}
.ModalMessage .span{
    margin: .2em 0;
}
.ModalMessage .input{
    margin: 1em 0;
    padding: 1em 0;
    border-bottom: 0.5px solid var(--border);
    border-top: 0.5px solid var(--border);
    width: 100%;
}
.ModalMessage .input input{
    padding: .5em .7em;
    margin: .5em 0;
    width: 100%;
    color: var(--dark_color);
    background: none;
    font-size: var(--fsize8);
    border-radius: var(--fsize10);
    border: 0.5px solid var(--border);
    line-height: .5;
}
.ModalMessage .input input::placeholder{
    opacity: .5;
}
.ModalMessage .buttons{
    display: flex;
    justify-content: flex-end;
    width: 100%;
}
.ModalMessage .name{
    display: flex;
    width: 100%;
}
.ModalMessage .buttons div{
    margin-right: 1em;
}
.checkBoxes{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 auto;
    margin: .5em 0 1em;
}
.checkBoxItem{
    display: flex;
    width: 40%;
    margin: .5em 0;
    flex-direction: column;
}
.checkBoxItem .span{
    margin-top: .5em;
    font-size: var(--fsize10);
}
.checkBoxItem_main{
    display: flex;
    align-items: center;
}
.checkBoxItem_main h3{
    font-family: 'GTEestiProDisplay-Medium';
    margin-top: .5em;
    margin-left: .5em;
    font-size: var(--fsize9);
}