.CustomersList{
    overflow-x: scroll;
    font-size: var(--fsize10);
    color: var(--dark_color);
}
.CustomersList *{
    color: var(--dark_color);
}
.title{
    font-family: 'GTEestiProDisplay-Medium';
    font-size: var(--fsize9);
    min-width: var(--minWidth);
    display: flex;
    justify-content: space-between;
    border-bottom: 0.5px solid var(--border);
}
.title *{
    margin: var(--fsize10);
    width: 30%;
}
.info{
    width: 30%;
}
.items{
    min-width: var(--minWidth);
}
.item{
    width: 100%;
    padding: 1em;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.notFinal{
    border-bottom: 0.5px solid var(--border);
}
.item img{
    height: 2.5em;
    margin-right: 1em;
}
.item_info{
    width: 30%;
    display: flex;
    flex-direction: var(--order_infoBlock_direction);
    align-items: center;
}
.item_info_text{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: .2em;
}
.value{
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}