.List{
    position: relative;
    display: flex;
    justify-content: center;
    z-index: 1;
}
.notifyContent{
    position: absolute;
    top: var(--fsize6);
    right: -1em;
    width: var(--notifications_width);
    background-color: var(--main_color);
    border: 0.5px solid var(--border);
    border-radius: 7px;
    max-height: 336px;
    overflow: hidden;
}
.wrapper{
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-radius: 7px;
    max-height: 290px;
    overflow-y: scroll;
}
.option{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 1em;
    font-size: var(--fsize10);
    border-top: .5px solid var(--border);
    user-select: none;
    cursor: pointer;
    color: var(--dark_color);
}
.read{
    color: var(--gray);
}
.close{
    cursor: pointer;
}
.clear{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1em;
    height: var(--fsize4);
    color: var(--orange);
    cursor: pointer;
    border-top: .5px solid var(--border);
    background-color: var(--main_color);
    width: 100%;
    font-size: var(--fsize10);
}
.option svg{
    width: var(--fsize10);
    height: var(--fsize10);
}
.option svg path{
    fill: var(--dark_color);
}
.content{
    width: 90%;
}
.title{
    font-family: 'GTEestiProDisplay-Bold';
    font-size: var(--fsize9);
}
.span{
    margin: .3em 0;
    font-size: var(--fsize10);
}