.AccountNav{
    width: var(--navbar_content_width);
    z-index: 1;
    margin: 2.5em 0;
}
.wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1em 1em 1em .7em;
    border: 0.5px solid var(--border);
    border-radius: 9px;
    cursor: pointer;
}
.content{
    display: flex;
    align-items: center;
    height: var(--fsize5);
}
.avatar{
    width: var(--fsize4);
    height: var(--fsize4);
    border-radius: 6px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}
.avatarWrapper{
    background-color: var(--orange);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.avatar img{
    height: 100%;
    background-color: var(--light_color);
}
.text{
    margin-left: .4em;
    height: var(--fsize4);
    display: flex;
    flex-flow: column;
    justify-content: space-around;
}
.name{
    font-size: var(--fsize8);
}
.email{
    font-size: var(--fsize11);
}
.AccountNav svg{
    width: var(--fsize6);
    height: var(--fsize6);
    cursor: pointer;
}
.AccountNav .arrowDownLong{
    width: var(--fsize11);
    height: var(--fsize11);
    margin: .5em 0 0 0;
}
.AccountNav svg path{
    fill: var(--dark_color);
}