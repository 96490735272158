.Branding{
    width: 100%;
    padding-right: 1em;
    height: calc(82vh - var(--header_height));
    overflow-y: scroll;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: flex-start;
}
.span{
    color: var(--dark_color);
    margin-top: 1em!important;
}
.block{
    border: 0.5px solid var(--border);
    border-radius: 1em;
    padding: 1.5em 2em;
    margin-bottom: 1em;
    width: 100%;
    display: flex;
    flex-direction: column; 
}
.bottom{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.bottom button{
    margin-left: auto;
}
.block h3{
    font-family: 'GTEestiProDisplay-Bold';
    font-size: var(--fsize8);
    margin-bottom: .2em;
}
.block .span{
    font-size: var(--fsize10);
}
.colorInput{
    padding: .5em .7em;
    margin: .5em 0;
    width: var(--colorInput_width);
    color: var(--dark_color);
    background: none;
    font-size: var(--fsize8);
    border-radius: var(--fsize10);
    border: 0.5px solid var(--border);
    line-height: .5;
    display: flex;
    align-items: center;
}
.colorInput span{
    margin-left: 1em;
    text-transform: uppercase;
}
.colorInput_input{
    background-color: aqua;
    height: 1.3em;
    width: 80%;
    display: flex;
    align-items: center;
    color: var(--dark_color);
    background: none;
    font-size: var(--fsize8);
    border-radius: .4em;
    border: 0.5px solid var(--border);
    overflow: hidden;
    line-height: .5;
}
.colorInput_input input{
    height: 3em;
    margin-left: -5px;
    min-width: 110%;
    cursor: pointer;
}
.block textarea{
    padding: .5em .7em;
    margin: .5em 0;
    width: 100%;
    color: var(--dark_color);
    background: none;
    font-size: var(--fsize9);
    border-radius: var(--fsize10);
    border: 0.5px solid var(--border);
    line-height: .5;
    height: 200px;
    resize: none;
}
.shopTypes{
    display: flex;
    flex-direction: var(--order_infoBlock_direction);
    margin-bottom: 1em;
}
.shopType{
    cursor: pointer;
    width: var(--shopType_width);
    border-radius: var(--fsize10);
    border: 0.5px solid var(--border);
    margin: .5em;
    position: relative;
}
.shopType *{
    cursor: pointer;
}
.shopType h3{
    font-family: 'GTEestiProDisplay-Regular';
    font-size: var(--fsize8);
}
.shopType span{
    display: flex;
    font-size: var(--fsize11);
}
.typeBlock::before{
    display: none;
    content: '';
    background-color: var(--orange);
    width: 20px;
    height: 20px;
    border-radius: 100%;
    position: absolute;
    top: 1em;
    right: 1em;
}
.typeBlock::after{
    display: none;
    content: '';
    width: 6px;
    height: 10px;
    border-right: .2em solid var(--stable_light);
    border-bottom: .2em solid var(--stable_light);
    transform: rotate(45deg);
    position: absolute;
    top: 1.3em;
    right: 1.46em;
}
.shopType label{
    display: block;
    width: 100%;
    height: 100%;
    padding: 1.5em 1em;
}
.shopType input[type=radio]{
    display: none;
}
.shopType input[type=radio]:checked + label h3{
    font-family: 'GTEestiProDisplay-Bold';
}
.shopType input[type=radio]:checked  + label::after{
    display: block;
}
.shopType input[type=radio]:checked  + label::before{
    display: block;
}
.checkBoxes{
    display: flex;
    flex-wrap: wrap;
}
.business{
    display: flex;
    margin: 10% .5em 1em;
}
.checkBox{
    margin: 0 1em 0 0;
    height: 3em;
    display: flex;
    align-items: center;
}
.checkBox .span{
    margin-left: .1em;
    margin-top: .5em;
    font-size: var(--fsize9);
}
.input_file{
    width: 100%;
    margin: 1em 0 2em;
}
.input_file .content{
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px dashed var(--border);
    border-radius: 12px;
    height: 180px;
    width: 100%;
    cursor: pointer;
    position: relative;
}
input[type=file]{
    outline:0;
    display: none;
    pointer-events:none;
    user-select:none
}
.clip{
    position: absolute;
    width: 50%;
    height: 65px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    color: var(--gray);
    text-align: center;
}
.clip svg{
    width: 30px;
    height: 40px;
}
.clip svg path{
    fill: var(--gray);
}
.mde{
    margin: 1em 0 2em;
}
.mde svg path{
    fill: var(--dark_color);
}
.links{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.link{
    margin: 1em 0 0;
    width: var(--half_width);
}
.link_span{
    font-family: 'GTEestiProDisplay-Bold';
    font-size: var(--fsize10);
}
.link_input{
    padding: .3em .5em;
    margin: .5em 0;
    width: 100%;
    color: var(--dark_color);
    background: none;
    font-size: var(--fsize8);
    border-radius: var(--fsize10);
    border: 0.5px solid var(--border);
    line-height: .5;
    display: flex;
    align-items: center;
}
.link_input input{
    font-size: var(--fsize9);
    margin-left: .5em;
    width: 88%;
    color: var(--dark_color);
    background-color: #00000000;
}
.link svg{
    width: var(--fsize5);
    height: var(--fsize5);
}