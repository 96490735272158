.status{
    font-size: var(--fsize10);
    padding: .5em .5em .3em .5em;
    font-family: 'GTEestiProDisplay-Medium';
    border-radius: 6px;
}
.Completed{
    color: var(--altgreen);
    background-color: var(--altgreen_transparent);
}
.Pending{
    color: var(--yellow);
    background-color: var(--yellow_transparent);
}
.Cancelled{
    color: var(--altred);
    background-color: var(--altred_transparent);
}
.Answered{
    color: var(--altgreen);
    background-color: var(--altgreen_transparent);
}
.PendingM{
    color: var(--purple);
    background-color: var(--purple_transparent);
}
.CustomerReply{
    color: var(--lightblue);
    background-color: var(--lightblue_transparent);
}