.ButtonLink{
    font-family: 'GTEestiProDisplay-Medium';
    color: var(--dark_color);
    font-size: var(--fsize9);
    border-radius: var(--fsize9);
    cursor: pointer;
    margin-left: 1em;
    display: flex;
    align-items: center;
}
.ButtonLink span{
    margin-top: .2em;
}
.orange{
    background-color: var(--orange);
    color: var(--stable_light);
}
.light{
    border: 0.5px solid var(--border);
}
.small{
    height: 2.2em;
    border-radius: .8em;
    font-size: var(--fsize9);
}
.small span{
    margin: 0 1.2em;
    font-size: var(--fsize9);
}
.large{
    height: 3em;
    padding: 0 1.2em;
}