.status{
    font-size: var(--fsize9);
    padding: .6em 1.1em .4em 1.1em;
    font-family: 'GTEestiProDisplay-Medium';
    border-radius: .7em;
    cursor: pointer;
}
.Close{
    color: var(--red);
    background-color: var(--altred_transparent);
}