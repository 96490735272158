.wrapper{
    width: 100%;
    height: 100%;
    margin: .5em 0;
    display: flex;
}
.wrapper h2{
    font-family: 'GTEestiProDisplay-Bold';
    font-size: var(--fsize8);
    margin: .5em 0;
}
.wrapper h3{
    font-size: var(--fsize9);
}
.content{
    width: var(--settingsContent);
    margin-left: 5%;
}
.select_navBar{
    display: var(--bMenu_display);
    padding: .2em .2em;
    margin: .5em 0;
    width: 100%;
    border-radius: 6px;
    color: var(--dark_color);
    background: none;
    font-size: var(--fsize8);
    border-radius: var(--fsize10);
    border: 0.5px solid var(--border);
    line-height: .5;
}
.navBar{
    width: 20%;
    display: var(--isMobile);
    flex-direction: column;
}
.item{
    margin: .2em 0;
    display: flex;
    justify-content: space-between;
    padding: 3.5% 7% 3.5% 7%;
    border-radius: var(--borderRadius);
    cursor: pointer;
    color: var(--dark_color);
    transition: .2s;
}
.item svg{
    opacity: 0;
    width: var(--fsize9);
    height: var(--fsize9);
}
.item svg path{
    fill: var(--dark_color);
}
.focus{
    background-color: var(--transparent);
    font-family: 'GTEestiProDisplay-Bold';
}
.focus svg{
    opacity: 1;
}