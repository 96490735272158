.main{
    border: 1px solid var(--border);
    margin:  2em 0;
    border-radius: 1em;
    padding: 2em;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}
.infoBlock{
    width: 100%;
    overflow: hidden;
    border: 1px solid var(--border);
    margin: 2em 0;
    border-radius: 1em;
    padding: 2em;
}
.infoBlock .content{
    width: var(--order_infoBlock);
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
}
.infoItem{
    width: var(--infoItems_width);
    font-size: var(--fsize9);
    margin: 0 0 5em;
}
.ip{
    margin: 0 0 5em;
}
.infoItem__ip{
    width: var(--infoItems_width);
    display: flex;
    font-size: var(--fsize9);
}
.infoItem__country{
    width: 50%;
    display: flex;
    align-items: center;
}
.infoItem__country img{
    margin-right: .5em;
}
.infoItem__device{
    width: var(--infoItems_width);
    font-size: var(--fsize9);
}
.h3_infoItem{
    font-family: 'GTEestiProDisplay-Bold';
    margin-bottom: .2em;
}
.goods{
    border: 1px solid var(--border);
    margin: 2em 0;
    border-radius: 1em;
    padding: 1.5em;
    width: 100%;
}
.goods__container{
    background-color: var(--transparent);
    margin: 1em 0;
    border-radius: 1em;
    padding: 1.2em;
    width: 100%;
    font-size: var(--fsize9);
}
.functions{
    display: flex;
    justify-content: space-between;
    margin: 1em 0 0;
    align-items: center;
}
.functions *{
    margin: 0 .5em;
}
.functions__button{
    background: none;
}
.h3{
    font-family: 'GTEestiProDisplay-Medium';
    font-size: var(--fsize9);
}
.h3_modal{
    font-family: 'GTEestiProDisplay-Bold';
    font-size: var(--fsize8);
}
.orange{
    color: var(--orange);
}
.main .info{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.main .blacklist{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.View img{
    width: var(--fsize2);
}
.buyer_info{
    margin-left: 1em;
    display: flex;
    justify-content: center;
    flex-direction: column;
    font-size: var(--fsize9);
}
.email{
    font-family: 'GTEestiProDisplay-Medium';
}
.status{
    margin-left: 1.5em;
}

.modal{
    background: var(--white);
    border: 1px solid var(--border);
    padding: 1.5em;
    border-radius: .7em;
    width: var(--modal_width);
}
.text{
    margin: 1em 0;
    font-size: var(--fsize9);
}
.modal .top{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.modal svg{
    margin-top: .2em;
    width: var(--fsize7);
    height: var(--fsize7);
    cursor: pointer;
}
.modal svg path{
    fill: var(--dark_color);
}
.buttons{
    margin: .5em 0;
    display: flex;
}
.buttons button{
    margin-right: 1em;
}
.buttons div{
    margin-right: 1em;
}
.buttons span{
    margin: 1em 0;
}
.input{
    padding: .7em 1em .5em;
    margin: 0 0 .7em;
    width: 95%;
    border-radius: 6px;
    color: var(--dark_color);
    background: none;
    font-size: var(--fsize9);
    border-radius: var(--fsize10);
    border: 0.5px solid var(--border);
    line-height: .5;
}
.input::placeholder {
    color:  var(--dark_color);
}