.Notifications{
    display: flex;
    flex-flow: column;
    align-items: center;
    position: relative;
}
.Notifications svg{
    width: var(--fsize6);
    height: var(--fsize6);
    cursor: pointer;
}
.Notifications svg path{
    fill: var(--dark_color);
}
.count{
    position: absolute;
    top: -.5em;
    left: .5em;
    background: var(--orange);
    border-radius: 1em;
    font-size: var(--fsize10);
    color:  #fff;
    padding: .1em .5em;
    cursor: pointer;
}