.Theme{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}
.Theme svg{
    width: var(--fsize6);
    height: var(--fsize6);
    cursor: pointer;
}
.Theme svg path{
    fill: var(--dark_color);
}