.TicketsList{
    overflow-x: scroll;
    font-size: var(--fsize10);
    color: var(--dark_color);
}
.title{
    font-family: 'GTEestiProDisplay-Medium';
    font-size: var(--fsize9);
    min-width: var(--minWidth);
    display: flex;
    justify-content: space-between;
    border-bottom: 0.5px solid var(--border);
}
.title *{
    margin: var(--fsize10);
    width: var(--ticketsListItem_width);
}
.title h4:nth-child(1){
    width: 10%;
}
.items{
    min-width: var(--minWidth);
}
.item{
    color: var(--dark_color);
    width: 100%;
    height: 5em;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.item:hover{
    color: var(--dark_color);
    width: 100%;
    height: 5em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--transparent);
    cursor: pointer;
}
.notFinal{
    border-bottom: 0.5px solid var(--border);
}
.item img{
    height: 2.5em;
    margin-right: 1em;
}
.item .value:nth-child(1){
    width: 10%;
}
.value{
    margin: var(--fsize10);
    width: var(--ticketsListItem_width);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}
.value__title{
    font-size: var(--fsize9);
}