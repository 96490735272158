.Pagination{
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 1em;
}
.pageSize{
    display: flex;
    align-items: center;
    font-size: var(--fsize10);
}
.pageSize select{
    font-size: var(--fsize10);
    -webkit-appearance: none;
    -moz-appearance: none;
    height: 2em;
    cursor: pointer;
    margin: 0 -.5em;
}