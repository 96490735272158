.TypeInfo{
    border: 1px solid var(--border);
    margin-bottom: 2em;
    border-radius: 1em;
    padding: 1em 2em 2em;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.h3{
    font-family: 'GTEestiProDisplay-Bold';
    font-size: var(--fsize9);
    margin: 1em 0 .5em;
}
small{
    font-family: 'GTEestiProDisplay-Regular';
    color: var(--gray);
}
.buttons{
    border: 1px solid var(--border);
    border-radius: 1em;
    overflow: hidden;
    margin: .5em 0;
    display: flex;
}
.radio{
    font-size: var(--fsize9);
	cursor: pointer;
	user-select: none;
    display: flex;
    align-items: center;
}
.buttons label{
    background: #00000000;
    padding: .6em 1.5em .4em;
    color: var(--dark_color);
    font-size: var(--fsize9);
    border-right: 0.5px solid var(--border);
	cursor: pointer;
	user-select: none;
}
.buttons input[type=radio]:checked + label {
    background-color: var(--orange);
    color: var(--stable_light);
}
.buttons input[type=radio] {
	display: none;
}
.radio:nth-child(3) label{
    border-right: none;
}

.Serials{
    margin-top: 1em;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.textarea_wrapper{
    width: 100%;
    border: 1px solid var(--border);
    border-radius: 1em; 
    overflow: hidden;
    padding: 1em;
    margin-bottom: 1em;
}
.textarea_wrapper textarea{
    background-color: var(--main_color);
    color: var(--dark_color);
    border: none;
    font-size: var(--fsize9);
    resize: none;
    width: 100%;
    height: 200px;
}
.delimiter{
    display: flex;
    align-items: center;
}
.delimiter span{
    margin-left: .5em;
}
.select{
    border: 1.5px solid var(--border);
    border-radius: 1em;
    overflow: hidden;
    width: auto;
    margin-right: 1em;
}
.select select{
    cursor: pointer;
    background-color: var(--main_color);
    color: var(--dark_color);
    padding: .7em 10em .5em 1em;
    margin-right: .5em;
    font-size: var(--fsize9);
}
.custom{
    width: 48%;
    display: none;
}
.custom  input{
    width: 100%;
    border: 1.5px solid var(--border);
    border-radius: 1em;
    overflow: hidden;
    font-size: var(--fsize9);
    padding: .7em 1em .5em 1em;
    background-color: var(--main_color);
    color: var(--dark_color);
}
.quantity{
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.quantityType{
    width: 48%;
}
.quantityType  input{
    width: 100%;
    border: 1.5px solid var(--border);
    border-radius: 1em;
    overflow: hidden;
    font-size: var(--fsize9);
    padding: .7em 1em .5em 1em;
    background-color: var(--main_color);
    color: var(--dark_color);
}
input::placeholder {
    color:  var(--dark_color);
}

.Service{
    margin-top: 1em;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.amount{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.amountSwitch{
    width: 48%;
}
.amountSwitch span{
    margin-left: .5em;
}
.amountInput{
    display: none;
    width: 48%;
}
.show{
    display: block;
}
.amountInput  input{
    width: 100%;
    border: 1.5px solid var(--border);
    border-radius: 1em;
    overflow: hidden;
    font-size: var(--fsize9);
    padding: .7em 1em .5em 1em;
    background-color: var(--main_color);
    color: var(--dark_color);
}

.Dynamic{
    margin-top: 1em;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.Dynamic  input{
    width: 100%;
    border: 1.5px solid var(--border);
    border-radius: 1em;
    overflow: hidden;
    font-size: var(--fsize9);
    padding: .7em 1em .5em 1em;
    margin: 1em 0;
    background-color: var(--main_color);
    color: var(--dark_color);
}
input::placeholder {
    color:  var(--dark_color);
}