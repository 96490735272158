.CouponsEdit .header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.compShow{
    display: block;
}
.compHide{
    display: none;
}
.submit{
    font-family: 'GTEestiProDisplay-Medium';
    font-size: var(--fsize9);
    border-radius: var(--fsize9);
    cursor: pointer;
    margin-left: 1em;
    display: flex;
    align-items: center;
    background-color: var(--orange);
    color: var(--stable_light);
    height: 3em;
    padding: 0 1.2em;
}
.func{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 60%;
}
.CouponsEdit_container{
    width: 100%;
    font-size: var(--fsize10);
    margin-top: 2em;
    padding: 2em 0;
    border-top: 0.5px solid var(--border);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}
.form{
    border: 0.5px solid var(--border);
    border-radius: 1em;
    padding: .5em 2em;
    width: 100%;
    margin-bottom: 2em;
}
.h3{
    font-family: 'GTEestiProDisplay-Medium';
    font-size: var(--fsize9);
    margin: 1em 0 .5em;
}
.select{
    margin: .5em 0;
    border-radius: 6px;
    color: var(--dark_color);
    background: none;
    border-radius: var(--fsize10);
    border: 0.5px solid var(--border);
    display: flex;
}
.select select{
    padding: .5em .7em;
    border-radius: 6px;
    color: var(--dark_color);
    background: none;
}
.input_wrapper{
    display: flex;
    position: relative;
    padding: .5em 0;
}
.input_wrapper div:nth-child(2){
    display: flex;
    position: absolute;
    right: .4em;
    top: .75em;
    width: auto;
    height: 50%;
    border-radius: .5em;
    align-items: center;
    justify-content: center;
    padding: 0 .7em;
}
.input_wrapper div:nth-child(2) span{
    margin-top: -.15em;
}
.input{
    padding: .5em 1em;
    width: 100%;
    color: var(--dark_color);
    background: none;
    font-size: var(--fsize9);
    border-radius: var(--fsize10);
    border: 0.5px solid var(--border);
    margin-right: 1em;
}
.input::placeholder{
    color: var(--dark_color);
    opacity: 1;
}
.couponInput_wrapper{
    display: flex;
    align-items: center;
    position: relative;
    padding: .5em 0;
}
.couponInput{
    padding: .5em 1em .5em 2em;
    width: 100%;
    color: var(--dark_color);
    background: none;
    font-size: var(--fsize9);
    border-radius: var(--fsize10);
    border: 0.5px solid var(--border);
    line-height: .5;
}
.couponInput::placeholder{
    color: var(--dark_color);
    opacity: 1;
}
.couponInput_wrapper span{
    position: absolute;
    left: .9em;
    font-size: var(--fsize9);
    opacity: .5;
}
.radio{
    margin: .5em 0;
    display: flex;
    align-items: center;
}
.form_radio{
    margin-right: 1em;
	font-size: var(--fsize9);
    display: flex;
    align-items: center;
    cursor: pointer;
}
.form_radio *{
    cursor: pointer;
}
.form_radio input[type=radio] {
	display: none;
}
.form_radio label:before {
	content: "";
	display: inline-block;
    vertical-align: middle;
    margin-right: .5em;
	width: var(--fsize9);
	height: var(--fsize9);
    border-radius: 100%;
    border: 0.5px solid var(--border);
}
.form_radio input[type=radio]:checked + label:before {
    border: .25em solid var(--orange);
}
.groupTypes{
    display: flex;
    margin-bottom: 1em;
}
.groupType{
    cursor: pointer;
    width: 250px;
    border-radius: var(--fsize10);
    border: 0.5px solid var(--border);
    margin: 0 .5em 0 0;
    position: relative;
}
.groupType *{
    cursor: pointer;
}
.groupType h2{
    font-size: var(--fsize9);
}
.groupType span{
    display: flex;
    font-size: var(--fsize11);
}
.typeBlock::before{
    display: none;
    content: '';
    background-color: var(--orange);
    width: var(--fsize8);
    height: var(--fsize8);
    border-radius: 100%;
    position: absolute;
    top: 1em;
    right: 1em;
}
.typeBlock::after{
    display: none;
    content: '';
    width: .5em;
    height: .8em;
    border-right: .2em solid var(--stable_light);
    border-bottom: .2em solid var(--stable_light);
    transform: rotate(45deg);
    position: absolute;
    top: 1.2em;
    right: 1.46em;
}
.groupType label{
    display: block;
    width: 100%;
    height: 100%;
    padding: 1.5em 1em;
}
.groupType input[type=radio]{
    display: none;
}
.groupType input[type=radio]:checked + label h2{
    font-family: 'GTEestiProDisplay-Bold';
}
.groupType input[type=radio]:checked  + label::after{
    display: block;
}
.groupType input[type=radio]:checked  + label::before{
    display: block;
}
.otherSettings{
    display: flex;
    flex-flow: var(--couponsFlow);
    align-content: space-between;
    width: 100%;
    height: 50vh;
}
.otherSetting{
    width: var(--couponsItems_width);
    border: 0.5px solid var(--border);
    border-radius: 1em;
    padding: .5em 2em;
    margin-bottom: 2em;
}
.top{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}