.Notifications{
    width: 100%;
    padding-right: 1em;
    height: calc(82vh - var(--header_height));
    overflow-y: scroll;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: flex-start;
}
.block{
    border: 0.5px solid var(--border);
    border-radius: 1em;
    padding: 1.5em 2em;
    margin-bottom: 1em;
    width: 100%;
    display: flex;
    flex-direction: column; 
}
.name .bottom{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.bottom{
    margin-left: auto;
}
.block h3{
    font-family: 'GTEestiProDisplay-Bold';
    font-size: var(--fsize8);
    margin-bottom: .2em;
}
.block .span{
    font-size: var(--fsize10);
}
.block input{
    padding: .5em .7em;
    margin: .5em 0;
    width: 100%;
    color: var(--dark_color);
    background: none;
    font-size: var(--fsize8);
    border-radius: var(--fsize10);
    border: 0.5px solid var(--border);
    line-height: .5;
}
.block textarea{
    padding: .5em .7em;
    margin: .5em 0;
    width: 100%;
    color: var(--dark_color);
    background: none;
    font-size: var(--fsize9);
    border-radius: var(--fsize10);
    border: 0.5px solid var(--border);
    line-height: .5;
    height: 200px;
    resize: none;
}
.block input::placeholder {
    color:  var(--dark_color);
}
.half{
    width: var(--half_width);
}
.half .bottom{
    display: flex;
    justify-content: flex-end;
}
.half .select{
    padding: .2em .2em;
    margin: .5em 0;
    width: 100%;
    border-radius: 6px;
    color: var(--dark_color);
    background: none;
    font-size: var(--fsize8);
    border-radius: var(--fsize10);
    border: 0.5px solid var(--border);
    line-height: .5;
}
.horizon{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.horizon_main{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.horizon_main h3{
    margin-top: .5em;
}
.switchList{
    display: flex;
    flex-wrap: wrap;
    margin: 1em 0;
}
.switch{
    margin: .2em 0;
    width: var(--half_width);
}
.switch .span{
    margin-left: .5em;
}
.dButton{
    padding: .5em 5em;
    margin: .5em auto .5em 0;
    color: var(--dark_color);
    background: none;
    font-size: var(--fsize8);
    border-radius: var(--fsize10);
    border: 0.5px solid var(--border);
    line-height: .5;
    display: flex;
    align-items: center;
}
.dButton svg{
    width: var(--fsize7);
    height: var(--fsize7);
    margin-right: .5em;
}
.dButton .span{
    margin-top: .2em;
    font-size: var(--fsize9);
}
.test_input{
    padding: .2em .5em;
    margin: .5em 0;
    width: 100%;
    color: var(--dark_color);
    background: none;
    font-size: var(--fsize8);
    border-radius: var(--fsize10);
    border: 0.5px solid var(--border);
    line-height: .5;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.test_input svg{
    width: var(-link-fsize6);
    height: var(--fsize6);
}
.test_input input{
    font-size: var(--fsize9);
    margin-left: .5em;
    width: 70%;
    color: var(--dark_color);
    background-color: #00000000;
    border: none;
    height: 1em;
}
.test_input input::placeholder{
    opacity: .5;
}