.Modal{
    z-index: 808;
    position: fixed;
}
.shadow{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: var(--alt_transparent);
    z-index: 808;
}
.content{
    position: fixed;
    width: var(--modal_width);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 808;
}