.Message{
    z-index: 99;
    position: fixed;
    top: calc(var(--header_height) + 1em);
    right: -100%;
    font-size: var(--fsize9);
    background-color: var(--dark_color);
    border-radius: 1em;
    padding: 1.5em;
    color: var(--light_color);
    display: flex;
    align-items: center;
    opacity: 0;
    transition: .7s ease-in-out;
}
.show{
    opacity: 1;
    position: fixed;
    right: 2%;
}
.Message svg{
    width: var(--fsize4);
    height: var(--fsize4);
    margin-right: 1em;
}
.Message h2{
    font-family: 'GTEestiProDisplay-Medium';
    font-size: var(--fsize7);
}
.success h2{
    color: var(--green);
}
.error h2{
    color: var(--red);
}