.header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: var(--reply_width);
    margin: 0 auto;
}
.h2{
    font-size: var(--fsize5);
}
.h3{
    font-family: 'GTEestiProDisplay-Bold';
    margin-bottom: .2em;
}
.wrapper{
    width: var(--reply_width);
    margin: 1em auto;
    overflow: hidden;
    border: 1px solid var(--border);
    border-radius: 1em;
    padding: 2em;
}
.wrapper .title{
    font-size: var(--fsize9);
    margin: 10em 0;
}
.wrapper .content{
    width: 120%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
}
.infoItem{
    width: var(--infoItems_width);
    font-size: var(--fsize9);
    margin: .5em 0 1.5em 1em;
}
.infoItem:nth-child(1) span{
    color: var(--orange);
}
.infoItem:nth-child(2) span{
    color: var(--orange);
}
.infoItem__country{
    width: 50%;
    display: flex;
    align-items: center;
}
.infoItem__country img{
    height: 40px;
    margin-right: .5em;
}
.wrapper .reply{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    border-top: 1px solid var(--border);
    padding: 2em 0 0;
}
.info_wrapper{
    display: flex;
    flex-direction: var(--order_infoBlock_direction);
    width: 100%;
    justify-content: space-between;
    align-items: center;
}
.info{
    display: flex;
}
.info .text{
    display: flex;
    margin-left: 1em;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}
.info .text span{
    font-size: var(--fsize9);
    margin-top: .5em;
}
.rating{
    font-size: var(--fsize8);
    display: flex;
    align-items: center;
    margin: 1em 0;
}
.stars{
    display: flex;
    margin-left: .5em;
}
.star svg{
    width: var(--fsize6);
    height: var(--fsize6);
}
.star svg path{
    fill: var(--orange);
}
.star_disabled svg{
    width: var(--fsize6);
    height: var(--fsize6);
}
.star_disabled svg path{
    fill: var(--orange_transparent);
}
.textarea_wrapper{
    width: 100%;
    border: 1px solid var(--border);
    border-radius: 1em; 
    overflow: hidden;
    padding: 1em;
    margin: 2em 0 1em;
}
.textarea_wrapper textarea{
    background-color: var(--main_color);
    color: var(--dark_color);
    border: none;
    font-size: var(--fsize9);
    resize: none;
    width: 100%;
    height: 120px;
}
.submit{
    width: 100%;
    display: flex;
    justify-content: flex-end;
}