.CouponsList{
    width: 100%;
    font-size: var(--fsize10);
    overflow-x: var(--overflowX);
}
.title{
    font-family: 'GTEestiProDisplay-Medium';
    font-size: var(--fsize9);
    display: flex;
    min-width: var(--minWidth);
    justify-content: space-between;
    border-bottom: 0.5px solid var(--border);
}
.title *{
    margin: var(--fsize10);
    width: 25%; 
}
.items{
    min-width: var(--minWidth);
}
.item{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.item_value{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: var(--blackList_item_width); 
    margin: 1em;
    height: 3em;
    position: relative;
    width: 25%; 
}
.item_value__action{
    display: flex;
    justify-content: flex-end;
    width: 5%;
    align-items: center;
    margin: 1em;
    height: 3em;
    position: relative;
}
.notFinal{
    border-bottom: 0.5px solid var(--border);
}
.data{
    width: 25%; 
}
.value{
    width: 25%; 
}
.note{
    width: 25%; 
}
.action{
    width: 5%;
    display: flex;
    justify-content: flex-end;
}
.action_icon{
    cursor: pointer;
}
.action_icon svg path{
    fill: var(--dark_color);
}
.action_list{
    display: none;
}
.show div{
    display: flex;
}