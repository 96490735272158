.InfoCardEdit{
    border: 0.5px solid var(--border);
    border-radius: 1em;
    padding: 2em;
    width: 100%;
    margin-bottom: 2em;
}
.h3{
    font-family: 'GTEestiProDisplay-Bold';
    font-size: var(--fsize9);
    margin: 1em 0;
}
.title{
    width: 100%;
}
select{
    padding-top: .2em;
    font-family: 'GTEestiProDisplay-Regular';
}
.mainInfo{
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.mainInfo .input{
    padding: .4em .7em;
    width: 100%;
    border-radius: 6px;
    color: var(--dark_color);
    background: none;
    font-size: var(--fsize9);
    border-radius: var(--fsize10);
    border: 0.5px solid var(--border);
    line-height: .5;
}
.input::placeholder {
    color:  var(--dark_color);
}
.editor{
    margin-top: 1em;
}
.editor svg path{
    fill: var(--dark_color)
}
.input_file{
    width: 100%;
}
.input_file .content{
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px dashed var(--border);
    border-radius: 12px;
    height: 180px;
    width: 100%;
    cursor: pointer;
    position: relative;
}
input[type=file]{
    outline:0;
    opacity:0;
    pointer-events:none;
    user-select:none
}
.clip{
    position: absolute;
    width: 50%;
    height: 65px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    color: var(--gray);
}
.clip svg{
    width: 30px;
    height: 40px;
}
.clip svg path{
    fill: var(--gray);
}
.name{
    position: absolute;
}
.checkboxes{
    margin: 1em 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.checkboxes .span{
    display: flex;
    align-items: center;
    font-size: var(--fsize9);
    color: var(--dark_color);
    margin-top: -.2em;
    margin-left: .5em;
}
.checkboxes .span span{
    margin-right: 1em;
    margin-top: .2em;
}
.checkboxes svg {
    margin-left: .2em;
    width: var(--fsize9);
    height: var(--fsize9);
}
.checkboxes svg path{
    fill: var(--dark_color);
}

[data-tooltip] {
    display: inline-block;
    position: relative;
    padding: 4px;
}
[data-tooltip]:before {
    content: attr(data-tooltip);
    display: none;
    position: absolute;
    background: var(--dark_color);
    color: var(--main_color);
    padding: .5em .8em .3em;
    font-size: var(--fsize10);
    line-height: 1.4;
    width: 100px;
    max-width: 200px;
    text-align: center;
    border-radius: .5em;
}
/* Dynamic horizontal centering */
[data-tooltip-position="top"]:before{
    left: 50%;
    -ms-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}
[data-tooltip-position="top"]:before {
    bottom: 100%;
    margin-bottom: 6px;
}
[data-tooltip]:after {
    content: '';
    display: none;
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
}
[data-tooltip-position="top"]:after{
    left: 50%;
    margin-left: -6px;
}
[data-tooltip-position="top"]:after {
    bottom: 100%;
    border-width: 6px 6px 0;
    border-top-color: var(--dark_color);
}
[data-tooltip]:hover:before,
[data-tooltip]:hover:after {
    display: block;
    z-index: 50;
}
