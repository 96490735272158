.Groups .header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.func{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 60%;
}
.submit{
    font-family: 'GTEestiProDisplay-Medium';
    color: var(--dark_color);
    font-size: var(--fsize9);
    border-radius: var(--fsize9);
    cursor: pointer;
    margin-left: 1em;
    display: flex;
    align-items: center;
    background-color: var(--orange);
    color: var(--stable_light);
    height: 3em;
    padding: 0 1.2em;
}
.search{
    display: flex;
    width: 45%;
}
.content{
    width: 99%;
    margin-top: 1em;
}
.ModalMessage{
    width: 100%;
    padding: 1.5em;
    background: var(--white);
    border-radius: var(--fsize10);
    display: flex;
    justify-content: space-between;
    border: 0.5px solid var(--border);
}
.ModalMessage_content{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 95%;
}
.ModalMessage svg{
    margin-top: .2em;
    width: var(--fsize7);
    height: var(--fsize7);
    cursor: pointer;
}
.ModalMessage svg path{
    fill: var(--dark_color);
}
.ModalMessage h3{
    font-family: 'GTEestiProDisplay-Bold';
    font-size: var(--fsize8);
}
.ModalMessage span{
    font-size: var(--fsize9);
    margin-top: 1em;
    margin-bottom: 1em;
}
.ModalMessage .buttons{
    display: flex;
}
.ModalMessage .buttons button{
    margin-right: 1em;
}