.NavAccounts{
    position: relative;
    display: flex;
    justify-content: center;
}
.wrapper{
    position: absolute;
    top: var(--fsize10);
    display: flex;
    flex-direction: column;
    width: var(--navbar_content_width);
    background-color: var(--main_color);
    border: 0.5px solid var(--border);
    overflow: hidden;
    border-radius: 7px;
}
.store{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 1em 1em 1em .7em;
    font-size: var(--fsize9);
    border-bottom: .5px solid var(--border);
    cursor: pointer;
}
.content{
    width: 90%;
    display: flex;
    align-items: center;
}
.create{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 1em 1em 1em .7em;
    font-size: var(--fsize9);
    border-bottom: .5px solid var(--border);
    color: var(--dark_color);
    cursor: pointer;
}
.create .avatar{
    display: flex;
    align-items: center;
    justify-content: center;
    width: var(--fsize5);
    height: var(--fsize5);
    background-color: var(--transparent);
    border-radius: 6px;
}
.create .avatar svg{
    width: var(--fsize10);
    height: var(--fsize10);
}
.avatar{
    width: var(--fsize4);
    height: var(--fsize4);
    border-radius: 6px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}
.avatarWrapper{
    background-color: var(--orange);
    width: 110%;
    height: 110%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.avatar img{
    height: 110%;
    background-color: var(--light_color);
}
.name{
    margin-left: .6em;
    margin-top: .2em;
    font-size: var(--fsize9);
}