.Categories{
    width: 100%;
    height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.content{
    text-align: center;
    max-width: 450px;
}
.content h3{
    font-family: 'GTEestiProDisplay-Medium';
    font-size: var(--fsize6);
    margin: .2em 0;
}
.content span{
    font-size: 18px;
}