.BlacklistsEdit .header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.submit{
    font-family: 'GTEestiProDisplay-Medium';
    font-size: var(--fsize9);
    border-radius: var(--fsize9);
    cursor: pointer;
    margin-left: 1em;
    display: flex;
    align-items: center;
    background-color: var(--orange);
    color: var(--stable_light);
    height: 3em;
    padding: 0 1.2em;
}
.func{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 60%;
}
.BlacklistsEdit_container{
    width: 100%;
    font-size: var(--fsize10);
    margin-top: 2em;
    padding: 2em 0;
    border-top: 0.5px solid var(--border);
    display: flex;
    flex-direction: var(--productEdit_direction);
    justify-content: space-between;
}
.form{
    border: 0.5px solid var(--border);
    border-radius: 1em;
    padding: .5em 2em;
    width: 100%;
    margin-bottom: 2em;
}
.h3{
    font-family: 'GTEestiProDisplay-Medium';
    font-size: var(--fsize9);
    margin: 1em 0 .5em;
}
.select{
    margin: .5em 0;
    width: 100%;
    border-radius: 6px;
    color: var(--dark_color);
    background: none;
    font-size: var(--fsize9);
    border-radius: var(--fsize10);
    border: 0.5px solid var(--border);
    display: flex;
    overflow: hidden;
}
select{
    padding: .5em .7em;
    width: 99%;
    border-radius: 6px;
    color: var(--dark_color);
    background: none;
    font-size: var(--fsize9);
}
.input{
    padding: .5em 1em;
    width: 100%;
    color: var(--dark_color);
    background: none;
    font-size: var(--fsize9);
    border-radius: var(--fsize10);
    border: 0.5px solid var(--border);
    line-height: .5;
}
.input::placeholder{
    color: var(--gray);
}
.textarea_wrapper{
    width: 100%;
    border: 1px solid var(--border);
    border-radius: 1em; 
    overflow: hidden;
    padding: 1em;
    margin-bottom: 1em;
}
.textarea_wrapper textarea{
    background-color: var(--white);
    color: var(--dark_color);
    border: none;
    font-size: var(--fsize9);
    resize: none;
    width: 100%;
    height: 120px;
}
textarea::placeholder{
    color: var(--gray);
}