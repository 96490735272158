.Reviews .header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.func{
    display: flex;
    width: 45%;
}
.content{
    width: 99%;
    margin-top: 1em;
}
.ModalMessage{
    width: 100%;
    padding: 1.5em;
    background: var(--white);
    border-radius: var(--fsize10);
    display: flex;
    justify-content: space-between;
    border: 0.5px solid var(--border);
}
.ModalMessage_content{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}
.ModalMessage svg{
    margin-top: .2em;
    width: var(--fsize7);
    height: var(--fsize7);
    cursor: pointer;
}
.ModalMessage svg path{
    fill: var(--dark_color);
}
.ModalMessage h3{
    font-family: 'GTEestiProDisplay-Bold';
    font-size: var(--fsize8);
}
.ModalMessage span{
    font-size: var(--fsize9);
    margin-top: 1em;
    margin-bottom: 1em;
}
.ModalMessage .buttons{
    display: flex;
}
.ModalMessage .buttons div{
    margin-right: 1em;
}
.select_container{
    display: flex;
    align-items: center;
    width: 100%;
}
.top{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.select{
    border: 1.5px solid var(--border);
    border-radius: 1em;
    overflow: hidden;
    width: auto;
    margin-right: 1em;
    width: 100%;
    padding: 0 1em;
}
.select select{
    cursor: pointer;
    width: 100%;
    background: var(--white);
    color: var(--dark_color);
    padding: .7em 0;
    margin-right: .5em;
    font-size: var(--fsize9);
}
.textarea_wrapper{
    width: 100%;
    border: 1px solid var(--border);
    border-radius: 1em; 
    overflow: hidden;
    padding: 1em;
    margin-bottom: 1em;
}
.textarea_wrapper textarea{
    background: var(--white);
    color: var(--dark_color);
    border: none;
    font-size: var(--fsize9);
    resize: none;
    width: 100%;
    height: 120px;
}