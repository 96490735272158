.AdditInfo{
    border: 0.5px solid var(--border);
    border-radius: 1em;
    padding: 2em;
    width: 100%;
    margin-bottom: 2em;
}
.title{
    display: flex;
    align-items: center;
    margin-top: 1em;
}
.title svg {
    margin: .2em 0 0 .2em; 
    width: var(--fsize9);
    height: var(--fsize9);
}
.title svg path{
    fill: var(--dark_color);
}
.h3{
    font-family: 'GTEestiProDisplay-Bold';
    font-size: var(--fsize9);
    margin: .2em 0;
    display: flex;
    align-items: center;
}
small{
    font-family: 'GTEestiProDisplay-Regular';
    color: var(--gray);
    margin: .2em 0 0; 
}
.h3 span{
    font-family: 'GTEestiProDisplay-Regular';
    font-size: var(--fsize10);
    margin: .2em 0;
}
.h3 span {
    padding-top: .3em;
}
.h3 svg {
    margin-left: .2em;
    width: var(--fsize9);
    height: var(--fsize9);
}
.h3 svg path{
    fill: var(--dark_color);
}
.textarea_wrapper{
    width: 100%;
    border: 1px solid var(--border);
    border-radius: 1em; 
    overflow: hidden;
    padding: 1em;
}
.textarea_wrapper textarea{
    background: #00000000;
    color: var(--dark_color);
    border: none;
    font-size: var(--fsize9);
    resize: none;
    width: 100%;
    height: 120px;
}
.checkboxes{
    margin: 1em 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 80%;
}
.checkboxes .span{
    display: flex;
    align-items: center;
    font-size: var(--fsize9);
    color: var(--dark_color);
    margin-top: -.2em;
    margin-left: .5em;
}
.spanTerms{
    display: flex;
    align-items: center;
    font-size: var(--fsize9);
    color: var(--dark_color);
    padding-bottom: .2em;
    margin-left: .5em;
}
.checkboxes .span span{
    margin-right: 1em;
    margin-top: .2em;
}
.checkboxes svg {
    margin-left: .2em;
    width: var(--fsize9);
    height: var(--fsize9);
}
.checkboxes svg path{
    fill: var(--dark_color);
}

[data-tooltip] {
    display: inline-block;
    position: relative;
    padding: 4px;
}
[data-tooltip]:before {
    content: attr(data-tooltip);
    display: none;
    position: absolute;
    background: var(--dark_color);
    color: var(--main_color);
    padding: .5em 1.2em .3em;
    font-size: var(--fsize10);
    line-height: 1.4;
    min-width: 15em;
    text-align: center;
    border-radius: .5em;
}
/* Dynamic horizontal centering */
[data-tooltip-position="top"]:before{
    left: 50%;
    -ms-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}
[data-tooltip-position="top"]:before {
    bottom: 100%;
    margin-bottom: 6px;
}
[data-tooltip]:after {
    content: '';
    display: none;
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
}
[data-tooltip-position="top"]:after{
    left: 50%;
    margin-left: -6px;
}
[data-tooltip-position="top"]:after {
    bottom: 100%;
    border-width: 6px 6px 0;
    border-top-color: var(--dark_color);
}
[data-tooltip]:hover:before,
[data-tooltip]:hover:after {
    display: block;
    z-index: 50;
}

.select_container{
    display: flex;
    align-items: center;
    width: 50%;
}
.select{
    border: 1.5px solid var(--border);
    border-radius: 1em;
    overflow: hidden;
    width: auto;
    margin-right: 1em;
    width: 100%;
    padding: 0 1em;
}
.select select{
    cursor: pointer;
    width: 100%;
    background: #00000000;
    color: var(--dark_color);
    padding: .7em 0;
    margin-right: .5em;
    font-size: var(--fsize9);
}
.editorHide{
    display: none;
}
.editor{
    display: flex;
}