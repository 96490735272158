.ProductEdit .header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.ProductEdit p{
    color: var(--red);
}
.submit{
    font-family: 'GTEestiProDisplay-Medium';
    color: var(--dark_color);
    font-size: var(--fsize9);
    border-radius: var(--fsize9);
    cursor: pointer;
    margin-left: 1em;
    display: flex;
    align-items: center;
    background-color: var(--orange);
    color: var(--stable_light);
    height: 3em;
    padding: 0 1.2em;
}
.func{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 60%;
}
.ProductEdit_container{
    width: 100%;
    font-size: var(--fsize10);
    margin-top: 2em;
    padding: 2em 0;
    border-top: 0.5px solid var(--border);
    display: flex;
    flex-direction: var(--productEdit_direction);
    justify-content: space-between;
}
.leftBlocks{
    width: var(--pELeftBlock_width);
}
.rightBlocks{
    width: var(--pERightBlock_width);
}