.header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 0 auto;
}
.h2{
    font-size: var(--fsize5);
}
.h3{
    font-family: 'GTEestiProDisplay-Bold';
    margin-bottom: .2em;
}
.wrapper{
    width: 100%;
    min-height: 60vh;
    margin: 1em auto;
    overflow: hidden;
    border: 1px solid var(--border);
    border-radius: 1em;
    display: flex;
    flex-direction: var(--ticketsReply_direction);
}
.wrapper .title{
    font-size: var(--fsize9);
    margin: 1em 1em 1em 0;
    width: 100%;
}
.wrapper .content{
    display: flex;
    width: var(--messageWrapper_width);
    height: 100%;
    flex-wrap: wrap;
    flex-direction: var(--ticketsReplyContent_direction);
    justify-content: space-between;
    align-items: flex-start;
    padding: 2em;
}
.infoItem{
    width: var(--chatWrapperItem_width);
    font-size: var(--fsize9);
    margin: .2em 1em 1.5em 0;
}
.infoItem:nth-child(2) span{
    color: var(--orange);
}
.infoItem:nth-child(3) span{
    color: var(--orange);
}
.infoItem__country{
    width: 50%;
    display: flex;
    align-items: center;
}
.wrapper .reply{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    border-top: 1px solid var(--border);
    padding: 2em 0 0;
}
.info_wrapper{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}
.info{
    display: flex;
}
.info .text{
    display: flex;
    margin-left: 1em;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}
.info .text span{
    font-size: var(--fsize9);
    margin-top: .5em;
}
.rating{
    font-size: var(--fsize8);
    display: flex;
    align-items: center;
}
.stars{
    display: flex;
    margin-left: .5em;
}
.star svg{
    width: var(--fsize6);
    height: var(--fsize6);
}
.star svg path{
    fill: var(--orange);
}
.star_disabled svg{
    width: var(--fsize6);
    height: var(--fsize6);
}
.star_disabled svg path{
    fill: var(--orange_transparent);
}
.submit{
    width: 100%;
    display: flex;
    justify-content: flex-end;
}
.chat_wrapper{
    width: var(--chatWrapper_width);
    border-left: var(--ticketsReply_borderR);
    border-top: var(--ticketsReply_borderB);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.chat{
    width: 100%;
    display: flex;
    padding: 1em;
    flex-direction: column;
}
.chat_send{
    width: 100%;
    height: 220px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid var(--border);
    cursor: pointer;
    position: relative;
}
.send{
    position: absolute;
    top: 47%;
    right: 7%;
    transform: translateY(-20%);
}
.send svg{
    width: var(--fsize5);
    height: var(--fsize5);
}
.send svg path{
    fill: var(--dark_color)
}
.textarea_wrapper{
    width: 95%;
    height: 70%;
    border: 1px solid var(--border);
    border-radius: 1em; 
    overflow: hidden;
    padding: 1em;
}
.textarea_wrapper textarea{
    width: 100%;
    background-color: #00000000;
    color: var(--dark_color);
    border: none;
    font-size: var(--fsize8);
    resize: none;
    height: 110px;
}
.message_wrapper{
    display: flex;
    flex-direction: column;
    margin: 2em;
    width: var(--messageWraaper_width);
}
.right{
    align-self: flex-end;
}
.left{
    align-self: flex-start;
}
.message_content{
    padding: 1em;
    font-size: var(--fsize9);
    overflow: hidden;
    border: 1px solid var(--border);
    border-radius: 1em;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.message_content .text{
    margin: 1em 0 0;
}
.message_wrapper .date{
    font-size: var(--fsize10);
    margin: .5em var(--fsize9) 0;
}