.Security{
    width: 100%;
    padding-right: 1em;
    height: calc(82vh - var(--header_height));
    overflow-y: scroll;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: flex-start;
}
input::placeholder{
    opacity: 1;
}
.block{
    border: 0.5px solid var(--border);
    border-radius: 1em;
    padding: 1.5em 2em;
    margin-bottom: 1em;
    width: 100%;
    display: flex;
    flex-direction: column; 
}
.bottom button{
    margin-left: auto;
}
.name .bottom{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.block h3{
    font-family: 'GTEestiProDisplay-Bold';
    font-size: var(--fsize8);
    margin-bottom: .2em;
}
.block .span{
    font-size: var(--fsize10);
}
.block input{
    padding: .5em .7em;
    margin: .5em 0;
    width: 100%;
    color: var(--dark_color);
    background: none;
    font-size: var(--fsize8);
    border-radius: var(--fsize10);
    border: 0.5px solid var(--border);
    line-height: .5;
}
.block textarea{
    padding: .5em .7em;
    margin: .5em 0;
    width: 100%;
    color: var(--dark_color);
    background: none;
    font-size: var(--fsize9);
    border-radius: var(--fsize10);
    border: 0.5px solid var(--border);
    line-height: .5;
    height: 200px;
    resize: none;
}
.block input::placeholder {
    color:  var(--dark_color);
}
.half{
    width: var(--half_width);
}
.half .bottom{
    display: flex;
    justify-content: flex-end;
}
.half .select{
    padding: .2em .2em;
    margin: .5em 0;
    width: 100%;
    border-radius: 6px;
    color: var(--dark_color);
    background: none;
    font-size: var(--fsize8);
    border-radius: var(--fsize10);
    border: 0.5px solid var(--border);
    line-height: .5;
}
.horizon{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.horizon_main{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 70%;
}
.horizon_main label{
    margin: 0 1em 1em;
}
.shopTypes{
    display: flex;
    flex-direction: var(--order_infoBlock_direction);
    margin-bottom: 1em;
}
.shopType{
    cursor: pointer;
    width: var(--shopType_width);
    border-radius: var(--fsize10);
    border: 0.5px solid var(--border);
    margin: .5em;
    position: relative;
}
.shopType *{
    cursor: pointer;
}
.shopType h3{
    font-family: 'GTEestiProDisplay-Regular';
    font-size: var(--fsize8);
}
.shopType span{
    display: flex;
    font-size: var(--fsize11);
}
.typeBlock::before{
    display: none;
    content: '';
    background-color: var(--orange);
    width: 20px;
    height: 20px;
    border-radius: 100%;
    position: absolute;
    top: 1em;
    right: 1em;
}
.typeBlock::after{
    display: none;
    content: '';
    width: 6px;
    height: 10px;
    border-right: .2em solid var(--stable_light);
    border-bottom: .2em solid var(--stable_light);
    transform: rotate(45deg);
    position: absolute;
    top: 1.3em;
    right: 1.46em;
}
.shopType label{
    display: block;
    width: 100%;
    height: 100%;
    padding: 1.5em 1em;
}
.shopType input[type=radio]{
    display: none;
}
.shopType input[type=radio]:checked + label h3{
    font-family: 'GTEestiProDisplay-Bold';
}
.shopType input[type=radio]:checked  + label::after{
    display: block;
}
.shopType input[type=radio]:checked  + label::before{
    display: block;
}
.purchaseList{
    width: 100%;
    font-size: var(--fsize10);
    color: var(--dark_color);
    overflow-x: scroll;
}
.purchaseList h4{
    font-size: var(--fsize9);
    font-family: 'GTEestiProDisplay-Medium';
}
.title{
    font-family: 'GTEestiProDisplay-Medium';
    font-size: var(--fsize9);
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-bottom: 0.5px solid var(--border);
}
.title *{
    margin: var(--fsize10);
    width: 10%;
}
.info{
    width: 30%;
}
.item{
    width: 100%;
    padding: 1em;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.notFinal{
    border-bottom: 0.5px solid var(--border);
}
.item img{
    height: 2.5em;
    margin-right: 1em;
}
.item_info{
    width: 30%;
    display: flex;
    flex-direction: var(--order_infoBlock_direction);
    align-items: flex-start;
}
.item_info_text{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: .2em;
}
.value{
    width: 10%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}
.link_input{
    padding: .2em .5em;
    margin: .5em 0;
    width: 100%;
    color: var(--dark_color);
    background: none;
    font-size: var(--fsize8);
    border-radius: var(--fsize10);
    border: 0.5px solid var(--border);
    line-height: .5;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.link_input svg{
    width: var(--fsize6);
    height: var(--fsize6);
}
.link_input input{
    font-size: var(--fsize9);
    margin-left: .5em;
    width: 70%;
    color: var(--dark_color);
    background-color: #00000000;
    border: none;
    height: 1em;
}
.newPassword{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: .5em 0 1em;
}