.Select{
    position: relative;
    display: flex;
    justify-content: flex-end;
}
.wrapper{
    position: absolute;
    top: var(--fsize6);
    display: flex;
    flex-direction: column;
    width: var(--select_width);
    background-color: var(--main_color);
    border: 0.5px solid var(--border);
    overflow: hidden;
    border-radius: 7px;
}
.option{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: var(--fsize4);
    font-size: var(--fsize10);
    cursor: pointer;
}
.option:nth-last-child(n+2){
    border-bottom: .5px solid var(--border);
}
.option:hover{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: var(--fsize4);
    font-size: var(--fsize10);
    cursor: pointer;
    font-family: 'GTEestiProDisplay-Bold';
}
.option svg{
    margin: 0 var(--fsize9);
}
.span{
    margin-top: .2em;
}