.Attention{
    padding: .5em .7em;
    margin: .5em 0;
    width: 100%;
    display: flex;
    color: var(--dark_color);
    background: var(--orange_transparent);
    border-radius: var(--fsize10);
    border: 1.5px solid var(--orange);
}
.Attention svg{
    height: var(--fsize7);
    width: var(--fsize8);
    margin-right: .7em;
}
.content{
    font-size: var(--fsize10);
    padding-top: .2em;
    color: var(--orange);
}
.content *{
    color: var(--orange);
}
.content a{
    text-decoration: underline;
}