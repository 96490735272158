.NavBar{
    width: var(--navbar_width);
    height: calc(100vh - var(--header_height));
    border-right: 0.5px solid var(--border);
    display: flex;
    flex-direction: column;
    align-items: center;
    position: var(--navBar_position);
    left: var(--navBar_position_left);
    background-color: var(--main_color);
    z-index: 100;
}