.react-mde{
    border-radius: 1em; 
    overflow: hidden;
}
.react-mde button{
    border: 0.5px solid var(--border);
    border-radius: 1em; 
    overflow: hidden;
    color: var(--dark_color);
}
.react-mde button:nth-child(1){
    font-family: 'GTEestiProDisplay-Medium';
    color: var(--dark_color);
    font-size: var(--fsize9);
    border-radius: var(--fsize10);
    align-items: center;
    justify-content: center;
    border: 0.5px solid var(--border);
    height: 2em;
    padding: .2em 1em 0 1em;
    margin: .5em;
}
.react-mde button:nth-child(2){
    font-family: 'GTEestiProDisplay-Medium';
    color: var(--dark_color);
    font-size: var(--fsize9);
    border-radius: var(--fsize10);
    align-items: center;
    justify-content: center;
    border: 0.5px solid var(--border);
    height: 2em;
    padding: .2em 1em 0 1em;
    margin: .5em;
}
.selected{
    display: none;
}
.mde-header{
    background-color: var(--main_color);
    border-bottom: 0.5px solid var(--border);
    display: flex;
    align-items: center;
}
.mde-text{
    background-color: var(--main_color);
    color: var(--dark_color);
    border: none;
    font-size: var(--fsize9);
}
.mde-textarea-wrapper textarea.mde-text{
    min-height: 210px;
    max-height: 500px;
}
.error{
  border: 1px solid var(--red)!important;
}