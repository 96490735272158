.App{
    width: 100%;
    display: flex;
    flex-direction: column;
}
.content{
    display: flex;
    height: calc(100vh - var(--header_height));
}
.main{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: var(--main_width);
    overflow-y: scroll;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
    transition: .5s;
    margin: 0 auto;
}
.main_content{
    margin: var(--fsize4) auto;
    width: 95%;
}
.someComponent{
    height: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.someComponent img{
    width: var(--fsize3);
}
.someComponent b{
    font-size: var(--fsize8);
    margin: .5em 0 .1em;
}
.someComponent span{
    font-size: var(--fsize10);
}
.back{
    display: none;
    z-index: 100;
    background-color: var(--alt_transparent);
    position: fixed;
    width: 100%;
    height: 100vh;
}