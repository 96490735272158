.GroupsEdit .header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.submit{
    font-family: 'GTEestiProDisplay-Medium';
    font-size: var(--fsize9);
    border-radius: var(--fsize9);
    cursor: pointer;
    margin-left: 1em;
    display: flex;
    align-items: center;
    background-color: var(--orange);
    color: var(--stable_light);
    height: 3em;
    padding: 0 1.2em;
}
.func{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 60%;
}
.GroupsEdit_container{
    width: 100%;
    font-size: var(--fsize10);
    margin-top: 2em;
    padding: 2em 0;
    border-top: 0.5px solid var(--border);
    display: flex;
    flex-direction: var(--productEdit_direction);
    justify-content: space-between;
    align-items: flex-start;
}
.form{
    border: 0.5px solid var(--border);
    border-radius: 1em;
    padding: .5em 2em;
    width: var(--groupsItemLeft_width);
    margin-bottom: 2em;
}
.h3{
    font-family: 'GTEestiProDisplay-Medium';
    font-size: var(--fsize9);
    margin: 1em 0 .5em;
}
.select{
    margin: .5em 0;
    width: 100%;
    border-radius: 6px;
    color: var(--dark_color);
    background: none;
    font-size: var(--fsize9);
    display: flex;
}
select{
    padding: .5em .7em;
    width: 99%;
    border-radius: 6px;
    color: var(--dark_color);
    background: none;
    font-size: var(--fsize9);
}
.input{
    padding: .5em 1em;
    width: 100%;
    color: var(--dark_color);
    background: none;
    font-size: var(--fsize9);
    border-radius: var(--fsize10);
    border: 0.5px solid var(--border);
    line-height: .5;
}
.input::placeholder{
    color: var(--dark_color);
    opacity: 1;
}
.groupTypes{
    display: flex;
    margin-bottom: 1em;
}
.groupType{
    cursor: pointer;
    width: 250px;
    border-radius: var(--fsize10);
    border: 0.5px solid var(--border);
    margin: 0 .5em 0 0;
    position: relative;
}
.groupType *{
    cursor: pointer;
}
.groupType h2{
    font-size: var(--fsize9);
}
.groupType span{
    display: flex;
    font-size: var(--fsize11);
}
.typeBlock::before{
    display: none;
    content: '';
    background-color: var(--orange);
    width: var(--fsize8);
    height: var(--fsize8);
    border-radius: 100%;
    position: absolute;
    top: 1em;
    right: 1em;
}
.typeBlock::after{
    display: none;
    content: '';
    width: .5em;
    height: .8em;
    border-right: .2em solid var(--stable_light);
    border-bottom: .2em solid var(--stable_light);
    transform: rotate(45deg);
    position: absolute;
    top: 1.2em;
    right: 1.46em;
}
.groupType label{
    display: block;
    width: 100%;
    height: 100%;
    padding: 1.5em 1em;
}
.groupType input[type=radio]{
    display: none;
}
.groupType input[type=radio]:checked + label h2{
    font-family: 'GTEestiProDisplay-Bold';
}
.groupType input[type=radio]:checked  + label::after{
    display: block;
}
.groupType input[type=radio]:checked  + label::before{
    display: block;
}
.ImageInput{
    border: 1px solid var(--border);
    margin-bottom: 2em;
    border-radius: 1em;
    padding: 1em 2em 2em;
    width: var(--groupsItemRight_width);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: center;
}
.h3{
    font-family: 'GTEestiProDisplay-Bold';
    font-size: var(--fsize9);
    margin: 1em 0 .5em;
}
.input_file{
    width: 100%;
}
.input_file .content{
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px dashed var(--border);
    border-radius: 12px;
    height: 180px;
    width: 100%;
    cursor: pointer;
    position: relative;
}
input[type=file]{
    outline:0;
    opacity:0;
    pointer-events:none;
    user-select:none
}
.clip{
    position: absolute;
    width: 50%;
    height: 65px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    color: var(--gray);
}
.clip svg{
    width: 30px;
    height: 40px;
}
.clip svg path{
    fill: var(--gray);
}
.name{
    position: absolute;
}