.InfoButton{
    display: var(--isMobile);
    flex-flow: column;
    align-items: flex-end;
    z-index: 1;
}
.InfoButton svg{
    width: var(--fsize6);
    height: var(--fsize6);
    cursor: pointer;
}
.InfoButton svg path{
    fill: var(--dark_color);
}