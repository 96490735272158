.MainInfo{
    border: 0.5px solid var(--border);
    border-radius: 1em;
    padding: 2em;
    width: 100%;
    margin-bottom: 2em;
}
.h3{
    font-family: 'GTEestiProDisplay-Bold';
    font-size: var(--fsize9);
    margin: .2em 0;
}
.title{
    width: 65%;
}
select{
    padding-top: .2em;
    font-family: 'GTEestiProDisplay-Regular';
}
.mainInfo{
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.price{
    width: 32%;
}
.mainInfo .input{
    padding: .4em .7em;
    margin: .5em 0;
    width: 100%;
    border-radius: 6px;
    color: var(--dark_color);
    background: #00000000;
    font-size: var(--fsize9);
    border-radius: var(--fsize10);
    border: 0.5px solid var(--border);
    line-height: .5;
}
.input::placeholder {
    color:  var(--dark_color);
}
.priceInput{
    margin: .5em 0;
    width: 100%;
    border-radius: 6px;
    color: var(--dark_color);
    background: #00000000;
    font-size: var(--fsize9);
    border-radius: var(--fsize10);
    border: 0.5px solid var(--border);
    display: flex;
    overflow: hidden;
}
.priceInput .input{
    padding: 0 .7em;
    background: #00000000;
    color: var(--dark_color);
    font-size: var(--fsize9);
    border-radius: var(--fsize10);
    border: none;
}
.priceInput select{
    width: 100px;
    padding: .2em .7em 0;
    background: #00000000;
    color: var(--dark_color);
    font-size: var(--fsize9);
    border-radius: var(--fsize10);
    border: none;
}
.editor{
    margin: .5em 0;
}
.editor p{
    color: var(--dark_color);
}
.editor svg path{
    fill: var(--dark_color)
}
.error>div{
    border: 1px solid var(--red);
}