.Dashboard .header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.func{
    display: flex;
    align-items: center;
}
.dashboard{
    overflow: hidden;
    border: 0.5px solid var(--border);
    border-radius: 14px;
    padding: var(--fsize10) var(--fsize6) 0;
    margin: 2em 0;
}
.content{
    width: var(--dashboard_content_width);
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 .5em 0;
}
.item{
    width: var(--dashboard_item_width);
    margin: 2em 0;
    display: flex;
    flex-direction: column;
}
.item span{
    font-size: var(--fsize8);
}
.item h3{
    font-family: 'GTEestiProDisplay-Bold';
    font-size: var(--fsize4);
    margin: .1em 0;
}
.item small{
    font-size: var(--fsize10);
    color: var(--orange);
}
.type{
    display: flex;
    height: var(--fsize7);
}
.type_item{
    font-family: 'GTEestiProDisplay-Medium';
    font-size: var(--fsize9);
    margin: 0 .5em;
    position: relative;
    width: var(--fsize5);
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
}
.focus{
    color: var(--orange);
}
.focus::before{
    content: '';
    position: absolute;
    width: 100%;
    bottom: 0;
    height: 3px;
    border-radius: 5px 5px 0px 0px;
    background-color: var(--orange);
}
.h2{
    font-size: var(--fsize5);
}
.h3{
    font-size: var(--fsize5);
    width: 100%;
    padding-top: 1em;
    text-align: center;
}