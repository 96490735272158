.ProductsList{
    width: 100%;
    margin-top: 1em;
    font-size: var(--fsize10);
}
.wrapper{
    overflow-x: var(--overflowX);
}
.title{
    font-family: 'GTEestiProDisplay-Medium';
    font-size: var(--fsize9);
    display: flex;
    min-width: var(--minWidth);
    justify-content: space-between;
    border-bottom: 0.5px solid var(--border);
}
.title *{
    margin: var(--fsize10);
    width: 16%;
}
.items{
    min-width: var(--minWidth);
}
.item{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.item_value{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 16%; 
    margin: 1em;
    height: 3em;
    position: relative
}
.item_value__action{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 16%; 
    margin: 1em;
    height: 3em;
    position: relative
}
.notFinal{
    border-bottom: 0.5px solid var(--border);
}
.action{
    display: flex;
    justify-content: flex-end;
}
.action_icon{
    cursor: pointer;
}
.action_icon svg path{
    fill: var(--dark_color);
}
.action_list{
    display: none;
}
.show div{
    display: flex;
}