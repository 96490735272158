.Links{
    width: 100%;
    display: flex;
    flex-direction: column;
}
.option{
    width: 100%;
    display: flex;
    align-items: center;
    margin: .3em 0;
    cursor: pointer;
    color: var(--dark_color);
    transition: .2s;
    position: relative;
}
.option::before{
    content: '';
    position: absolute;
    width: 0;
    height: 100%;
    border-radius: 0px 5px 5px 0px;
    background-color: var(--orange);
    transition: .2s;
}
.arrow{
    margin-right: 10%;
}
.arrow svg{
    width: var(--fsize10);
    height: var(--fsize10);
}
.arrow svg path{
    fill: var(--dark_color);
}
.img{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3em;
    margin: 0 .7em;
}
.img svg{
    width: var(--fsize5);
    height: var(--fsize8);
}
.img svg path{
    fill: var(--dark_color);
}
.span{
    font-size: var(--fsize8);
    margin-top: .2em;
    transition: .2s;
}
.secondary{
    display: none;
    transition: .2s;
}
.secondaryOrders{
    display: none;
    transition: .2s;
}
.show{
    display: block;
}
.focus{
    position: relative;
    display: flex;
    z-index: -100;
    width: 100%;
}
.focus::after{
    content: '';
    position: absolute;
    left: .7em;
    width: 90%;
    height: 100%;
    border-radius: .7em;
    background-color: var(--orange_transparent);
}
.focus::before{
    content: '';
    position: absolute;
    width: 2px;
    height: 100%;
    border-radius: 0px 5px 5px 0px;
    background-color: var(--orange);
}
.focus span{
    color: var(--orange);
}
.focus svg path{
    fill: var(--orange);
}
.secondary .option{
    margin: 0;
}