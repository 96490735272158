.ImageInput{
    border: 1px solid var(--border);
    margin-bottom: 2em;
    border-radius: 1em;
    padding: 1em 2em 2em;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: center;
}
.h3{
    font-family: 'GTEestiProDisplay-Bold';
    font-size: var(--fsize9);
    margin: 1em 0 .5em;
}
small{
    font-family: 'GTEestiProDisplay-Regular';
    color: var(--gray);
}
.input_file{
    width: 100%;
}
.input_file .content{
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px dashed var(--border);
    border-radius: 12px;
    height: 180px;
    width: 100%;
    cursor: pointer;
    position: relative;
}
input[type=file]{
    outline:0;
    opacity:0;
    pointer-events:none;
    user-select:none
}
.clip{
    position: absolute;
    width: 50%;
    height: 65px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    color: var(--gray);
}
.clip svg{
    width: 30px;
    height: 40px;
}
.clip svg path{
    fill: var(--gray);
}
.name{
    position: absolute;
}