.Customers .header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.func{
    display: flex;
    width: 45%;
}
.content{
    width: 99%;
    margin-top: 1em;
}