.Account{
    display: flex;
    flex-flow: column;
    align-items: flex-end;
    cursor: pointer;
    z-index: 1;
}
.content{
    display: flex;
    align-items: center;
    height: var(--fsize6);
}
.avatar{
    width: var(--fsize5);
    height: var(--fsize5);
    border-radius: 6px;
    overflow: hidden;
}
.avatarWrapper{
    background-color: var(--orange);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.avatarWrapper img{
    height: 100%;
    background-color: var(--light_color);
}
.name{
    display: var(--isMobile);
    margin: .2em 0 0 .4em;
}
.Account svg{
    width: var(--fsize6);
    height: var(--fsize6);
    cursor: pointer;
}
.Account svg path{
    fill: var(--dark_color);
}