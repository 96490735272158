.Payments{
    width: 100%;
    padding-right: 1em;
    height: calc(82vh - var(--header_height));
    overflow-y: scroll;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: flex-start;
}
.block{
    border: 0.5px solid var(--border);
    border-radius: 1em;
    padding: 1.5em 2em;
    margin: 1em 0;
    width: 100%;
    display: flex;
    flex-direction: column;
}
.check{
    width: 1em;
    height: 1em;
    margin-left: auto;
    border-radius: 1em;
    background-color: var(--orange);
    cursor: pointer;
    position: relative;
}
.check::before{
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -60%) rotate(45deg);
    width: .25em;
    height: .5em;
    border-right: .1em solid var(--stable_light);
    border-bottom: .1em solid var(--stable_light);
}
.checked{
    background-color: var(--green);
}
.compShow{
    display: block;
}
.compHide{
    display: none;
}
.cart{
    display: flex;
    align-items: flex-start;
}
.name .bottom{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.bottom button{
    margin-left: auto;
}
.block h3{
    font-family: 'GTEestiProDisplay-Bold';
    font-size: var(--fsize8);
    margin-bottom: .2em;
}
.block .span{
    font-size: var(--fsize10);
}
.countInput{
    padding: .5em .7em;
    margin: .5em 0;
    width: 100%;
    color: var(--dark_color);
    background: none;
    font-size: var(--fsize9);
    border-radius: var(--fsize10);
    border: 0.5px solid var(--border);
    line-height: .5;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.countInput::before{
    content: '$';
    opacity: .5;
}
.countInput::after{
    content: 'or more';
    min-width: 67px;
    opacity: .5;
}
.countInput input{
    width: 80%;
    margin: 0 .5em;
    font-size: var(--fsize9);
    color: var(--dark_color);
    background: #00000000;
}
.voidInput{
    padding: .5em .7em;
    margin: .5em 0;
    width: 100%;
    color: var(--dark_color);
    background: none;
    font-size: var(--fsize9);
    border-radius: var(--fsize10);
    border: 0.5px solid var(--border);
    line-height: .5;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.voidInput::before{
    content: 'after';
    opacity: .5;
}
.voidInput::after{
    content: 'hours';
    opacity: .5;
}
.voidInput input{
    width: 80%;
    margin: 0 .5em;
    font-size: var(--fsize9);
    color: var(--dark_color);
    background: #00000000;
}
.cartInput{
    padding: .5em .7em;
    margin: .5em 0;
    width: 100%;
    color: var(--dark_color);
    background: none;
    font-size: var(--fsize9);
    border-radius: var(--fsize10);
    border: 0.5px solid var(--border);
    line-height: .5;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.cartInput::before{
    content: '$';
    opacity: .5;
}
.cartInput::after{
    content: 'maximum';
    opacity: .5;
}
.cartInput input{
    width: 90%;
    margin: 0 .5em;
    color: var(--dark_color);
    background: #00000000;
    font-size: var(--fsize9);
}
.block input::placeholder {
    color:  var(--dark_color);
}
.half{
    margin-bottom: auto;
    width: var(--half_width);
}
.half .bottom{
    display: flex;
    justify-content: flex-end;
}
.payments{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.payment{
    margin: 1em 0 0;
    width: var(--half_width);
    cursor: pointer;
}
.payment_span{
    font-family: 'GTEestiProDisplay-Bold';
    font-size: var(--fsize10);
}
.payment_text{
    margin-top: .2em;
    margin-left: .5em;
    font-size: var(--fsize9);
}
.payment_button{
    padding: .3em .5em;
    margin: .5em 0;
    width: 100%;
    color: var(--dark_color);
    background: none;
    font-size: var(--fsize8);
    border-radius: var(--fsize10);
    border: 0.5px solid var(--border);
    line-height: .5;
    display: flex;
    align-items: center;
}
.payment svg{
    width: var(--fsize5);
    height: var(--fsize5);
}
.attention{
    margin-left: auto;
    max-width: 100%;
}
.horizon{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.horizon_main{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.horizon_main h3{
    margin-top: .5em;
}
.soc{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.dButton_wrapper{
    width: var(--third_width);
    margin: .5em 0 1em;
}
.dButton{
    padding: .5em .7em;
    width: 100%;
    margin: .5em auto 0 0;
    color: var(--dark_color);
    background: none;
    font-size: var(--fsize8);
    border-radius: var(--fsize10);
    border: 0.5px solid var(--border);
    line-height: .5;
    display: flex;
    align-items: center;
    justify-content: center;
}
.dButton svg{
    width: var(--fsize7);
    height: var(--fsize7);
    margin-right: .5em;
}
.dButton .span{
    margin: .2em 1em 0 .5em;
    font-size: var(--fsize9);
}
.dButton_checkBox{
    display: flex;
    align-items: center;
    justify-content: center;
}
.dButton_checkBox .span{
    margin: .5em .5em 0;
    font-size: calc(var(--fsize9) - .1em);
}
.ModalMessage{
    width: 600px;
    max-width: 100%;
    padding: 1.5em;
    background: var(--white);
    border-radius: var(--fsize10);
    display: flex;
    justify-content: space-between;
    border: 0.5px solid var(--border);
}
.ModalMessage_content{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}
.ModalMessage svg{
    width: var(--fsize6);
    height: var(--fsize6);
    cursor: pointer;
}
.customInput{
    width: 100%;
    margin: .2em 0;
}
.customInput_input{
    padding: .3em .5em;
    margin: .2em 0;
    width: 100%;
    color: var(--dark_color);
    background: #00000000;
    font-size: var(--fsize8);
    border-radius: var(--fsize10);
    border: 0.5px solid var(--border);
    line-height: .5;
    display: flex;
    align-items: center;
    position: relative;
}
.customInput_input input{
    font-size: var(--fsize9);
    margin-left: .5em;
    width: 88%;
    color: var(--dark_color);
    background-color: #00000000;
}
.customSVG svg path{
    fill: var(--dark_color);
}
.window{
    padding: 0;
    overflow: hidden;
    margin: .2em 0;
    width: 100%;
    height: auto;
    color: var(--dark_color);
    background: #00000000;
    font-size: var(--fsize8);
    border-radius: var(--fsize10);
    border: 0.5px solid var(--border);
    line-height: .5;
    display: flex;
    align-items: center;
    justify-content: center;
}
.window video{
    margin: -2px;
}
.to{
    padding: .5em;
    margin: .4em 0;
    width: 100%;
    color: var(--stable_light);
    background: var(--orange);
    font-size: var(--fsize9);
    border-radius: var(--fsize10);
    border: 0.5px solid var(--border);
    line-height: .5;
    display: flex;
    align-items: center;
    justify-content: center;
}
.to svg{
    margin-left: .5em;
    width: var(--fsize9);
    height: var(--fsize9);
}
.to span{
    margin-top: .2em;
}
.ModalMessage h3{
    font-family: 'GTEestiProDisplay-Bold';
    font-size: var(--fsize8);
    margin-bottom: 1em;
}
.ModalMessage h4{
    font-family: 'GTEestiProDisplay-Bold';
    font-size: var(--fsize9);
    margin: .5em 0 0;
}
.ModalMessage .span{
    margin: .2em 0;
}
.ModalMessage .input{
    margin: 1em 0;
    padding: 1em 0;
    border-bottom: 0.5px solid var(--border);
    border-top: 0.5px solid var(--border);
    width: 100%;
}
.ModalMessage .input input{
    padding: .5em .7em;
    margin: .5em 0;
    width: 100%;
    color: var(--dark_color);
    background: none;
    font-size: var(--fsize8);
    border-radius: var(--fsize10);
    border: 0.5px solid var(--border);
    line-height: .5;
}
.ModalMessage .input input::placeholder{
    opacity: .5;
}
.ModalMessage .buttons{
    margin-top: 1em;
    display: flex;
    justify-content: flex-end;
    width: 100%;
}
.ModalMessage .name{
    display: flex;
    width: 100%;
}
.ModalMessage .buttons button{
    margin-right: 1em;
}
.switchH{
    display: flex;
    align-items: center;
    margin: .5em 0 0;
}
.switchH .span{
    margin: .2em .5em 0;
    font-size: calc(var(--fsize9) - .2em);
}