.status{
    font-size: var(--fsize10);
    padding: .5em .5em .3em .5em;
    font-family: 'GTEestiProDisplay-Medium';
    border-radius: 6px;
}
.Serials{
    color: var(--purple);
    background-color: var(--purple_transparent);
}
.Service{
    color: var(--lightblue);
    background-color: var(--lightblue_transparent);
}
.File{
    color: var(--yellow);
    background-color: var(--yellow_transparent);
}
.Email{
    color: var(--purple);
    background-color: var(--purple_transparent);
}
.Country{
    color: var(--yellow);
    background-color: var(--yellow_transparent);
}
.IP{
    color: var(--dark_color);
    background-color: var(--transparent);
}
.Percentage{
    color: var(--lightblue);
    background-color: var(--lightblue_transparent);
}
.Amount{
    color: var(--lightblue);
    background-color: var(--lightblue_transparent);
}
.PRO{
    color: var(--orange);
    background-color: var(--orange_transparent);
}
.Business{
    color: var(--orange);
    background-color: var(--orange_transparent);
}