.Action{
    position: relative;
    display: flex;
    justify-content: flex-end;
}
.wrapper{
    position: absolute;
    top: var(--fsize6);
    display: none;
    flex-direction: column;
    width: 9em;
    background-color: var(--main_color);
    border: 0.5px solid var(--border);
    overflow: hidden;
    border-radius: 7px;
    z-index: 100;
}
.option{
    display: flex;
    align-items: center;
    color: var(--orange);
    background-color: var(--orange_transparent);
    justify-content: flex-start;
    height: var(--fsize5);
    font-size: var(--fsize10);
    cursor: pointer;
}
.option svg{
    margin: 0 var(--fsize9);
    width: var(--fsize9);
    height: var(--fsize9);
}
.option svg path{
    fill: var(--orange);
}
.option:nth-last-child(n+2){
    border-bottom: .5px solid var(--border);
}
.option:nth-last-child(n+2){
    border-bottom: .5px solid var(--border);
    color: var(--dark_color);
    background-color: var(--white);
}
.option:nth-last-child(n+2) svg path{
    fill: var(--dark_color);
}
.option:hover{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: var(--fsize5);
    font-size: var(--fsize10);
    cursor: pointer;
    font-family: 'GTEestiProDisplay-Bold';
}
.span{
    margin-top: .2em;
}